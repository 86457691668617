import * as Desk from 'constants/Desk'
import {
  USER_MANAGEMENT_READ,
  GROUP_MANAGEMENT_READ,
  GROUP_OWNER_READ,
  ACTIVITY_LOG_READ,
  ACCESS_ACCOUNT_READ,
  PUNCH_LOGS_READ,
  HEATMAP_ACCESS_HISTORY_READ,
  INTEGRATION_API_MANAGEMENT_READ,
  ROLE_MANAGEMENT_READ,
  LISTENING_KEYWORD_READ,
  LISTENING_KEYWORD_WRITE,
  LISTENING_CAT_TAG_READ,
  LISTENING_CAT_TAG_WRITE,
  LISTENING_MONITOR_READ,
  LISTENING_MONITOR_WRITE,
  LISTENING_ENGAGEMENT_READ,
  LISTENING_ENGAGEMENT_WRITE,
  LISTENING_TEMPLATE_SETTING_READ,
  LISTENING_TEMPLATE_SETTING_WRITE,
  LISTENING_GROUP_SETTING_READ,
  LISTENING_GROUP_SETTING_WRITE,
  EMAIL_NOTIFICATION_READ,
  EMAIL_NOTIFICATION_WRITE,
  LISTENING_BLACKLIST_READ,
  DESK_GROUP_MANAGE_READ,
  DESK_AUTO_ASSIGNMENT_MANAGEMENT_READ,
  DESK_AUTO_SENTIMENT_READ,
  DESK_POST_BLOCKER_READ,
  DESK_CUSTOMER_TAG_WRITE,
  DESK_WORKING_STATUS_READ,
  DESK_QC_WRITE
} from 'constants/PermissionsList'

export default {
  GROUP_SETTING_PAGE: {
    all: [USER_MANAGEMENT_READ, [GROUP_MANAGEMENT_READ, GROUP_OWNER_READ]]
  },
  SYSTEM_SETTING_PAGE: {
    all: [ACCESS_ACCOUNT_READ]
  },
  SENTIMENT_SETTING_PAGE: {
    all: [USER_MANAGEMENT_READ, GROUP_MANAGEMENT_READ]
  },
  ACTIVITY_LOG_PAGE: {
    all: [ACTIVITY_LOG_READ]
  },
  PUNCH_LOG_PAGE_SETTING: {
    all: [PUNCH_LOGS_READ]
  },
  HEATMAP_HISTORY: {
    all: [HEATMAP_ACCESS_HISTORY_READ]
  },
  INTEGRATION_SETTING: {
    all: [INTEGRATION_API_MANAGEMENT_READ]
  },
  CUSTOM_ROLE: {
    all: [ROLE_MANAGEMENT_READ]
  },
  KEYWORD_SETTING: {
    listening: [LISTENING_KEYWORD_READ],
    crm: [Desk.permissions.crm.CRM_KEYWORD_READ]
  },
  TAGS_SETTING: {
    listening: [LISTENING_CAT_TAG_READ],
    crm: [Desk.permissions.crm.CRM_CATEGORY_AND_TAG_READ]
  },
  MONITOR_SETTING: {
    listening: [LISTENING_MONITOR_READ]
  },
  ENGAGEMENT_SETTING: {
    listening: [LISTENING_ENGAGEMENT_READ],
    crm: [Desk.permissions.crm.CRM_CHANNEL_READ]
  },
  TEMPLATE_SETTING: {
    listening: [LISTENING_TEMPLATE_SETTING_READ]
  },
  PRODUCT_GROUP_MANAGEMENT: {
    listening: [LISTENING_GROUP_SETTING_READ],
    crm: [DESK_GROUP_MANAGE_READ]
  },
  EMAIL_NOTIFICATION_SETTING: {
    listening: [EMAIL_NOTIFICATION_READ]
  },
  BLACKLIST_SETTING: {
    listening: [LISTENING_BLACKLIST_READ]
  },
  SLA_SETTING: {
    crm: [Desk.permissions.crm.CRM_SLA_READ]
  },
  CANNED_RESPONSE_SETTING: {
    crm: [Desk.permissions.crm.CRM_CANNED_RESPONSE_READ]
  },
  TICKET_CREATEION_SETTING: {
    crm: [Desk.permissions.crm.CRM_SPLIT_TICKET_READ]
  },
  AUTO_ASSIGN_SETTING: {
    crm: [DESK_AUTO_ASSIGNMENT_MANAGEMENT_READ]
  },
  AUTO_SENTIMENT_SETTING: {
    crm: [DESK_AUTO_SENTIMENT_READ]
  },
  POST_BLOCKER_SETTING: {
    crm: [DESK_POST_BLOCKER_READ]
  },
  VERIFICATION_LINK_SETTING: {
    crm: [Desk.permissions.crm.CRM_VERIFICATION_READ]
  },
  CUSTOMER_TAG_SETTING: {
    crm: [DESK_CUSTOMER_TAG_WRITE]
  },
  WORKING_STATUS_SETTING: {
    crm: [DESK_WORKING_STATUS_READ]
  },
  QC_SETTING: {
    crm: [DESK_QC_WRITE]
  }
}
