// CENTER PERMISSIONS
export const USER_MANAGEMENT_WRITE = 'center.user_management.write'
export const USER_MANAGEMENT_READ = 'center.user_management.read'
export const ROLE_MANAGEMENT_WRITE = 'center.role_management.write'
export const ROLE_MANAGEMENT_READ = 'center.role_management.read'
export const GROUP_MANAGEMENT_WRITE = 'center.group_management.write'
export const GROUP_MANAGEMENT_READ = 'center.group_management.read'
export const ACTIVITY_LOG_READ = 'center.activity_log.read'
export const INTEGRATION_API_MANAGEMENT_READ = 'center.integration_api_management.read'
export const INTEGRATION_API_MANAGEMENT_WRITE = 'center.integration_api_management.write'
export const HEATMAP_ACCESS_HISTORY_READ = 'center.heatmap_access_history.read'
export const GROUP_OWNER_READ = 'center.group_owner.read'
export const GROUP_OWNER_WRITE = 'center.group_owner.write'
export const PUNCH_LOGS_READ = 'center.punch_logs.read'
export const APPLY_TAG_ALL_KEYWORD_WRITE = 'center.apply_tag_all_keyword.write'
export const INVISIBLE_READ = 'center.invisible.read'
export const ACCESS_ACCOUNT_READ = 'center.access_account.read'
export const ACCESS_ACCOUNT_WRITE = 'center.access_account.write'
// export const PUNCH_LOGS_WRITE = 'center.punch_logs.write'

// LISTENING PERMISSIONS
export const LISTENING_KEYWORD_READ = 'listening.keyword.read'
export const LISTENING_KEYWORD_WRITE = 'listening.keyword.write'
export const EMAIL_NOTIFICATION_READ = 'listening.email_notification.read'
export const EMAIL_NOTIFICATION_WRITE = 'listening.email_notification.write'
export const LISTENING_GROUP_SETTING_READ = 'listening.group_setting.read'
export const LISTENING_GROUP_SETTING_WRITE = 'listening.group_setting.write'
export const LISTENING_MONITOR_READ = 'listening.monitor.read'
export const LISTENING_MONITOR_WRITE = 'listening.monitor.write'
export const LISTENING_TEMPLATE_SETTING_READ = 'listening.template_setting.read'
export const LISTENING_TEMPLATE_SETTING_WRITE = 'listening.template_setting.write'
export const LISTENING_CAT_TAG_READ = 'listening.cat_and_tag.read'
export const LISTENING_CAT_TAG_WRITE = 'listening.cat_and_tag.write'
export const LISTENING_BLACKLIST_READ = 'listening.blacklist.read'
export const LISTENING_BLACKLIST_WRITE = 'listening.blacklist.write'
export const LISTENING_SHARE_TEMPLATE_ALL = 'listening.share_template_all.write'
export const LISTENING_SHARE_TEMPLATE = 'listening.share_template.write'
export const LISTENING_ENGAGEMENT_READ = 'listening.engagement.read'
export const LISTENING_ENGAGEMENT_WRITE = 'listening.engagement.write'

// CRM PERMISSIONS
export const DESK_AUTO_ASSIGNMENT_MANAGEMENT_READ = 'crm.auto_assignment_management.read'
export const DESK_AUTO_ASSIGNMENT_MANAGEMENT_WRITE = 'crm.auto_assignment_management.write'
export const DESK_POST_BLOCKER_READ = 'crm.post_blocker.read'
export const DESK_POST_BLOCKER_WRITE = 'crm.post_blocker.write'
export const DESK_AUTO_SENTIMENT_READ = 'crm.auto_sentiment.read'
export const DESK_AUTO_SENTIMENT_WRITE = 'crm.auto_sentiment.write'
export const DESK_GROUP_MANAGE_READ = 'crm.group_manage.read'
export const DESK_GROUP_MANAGE_WRITE = 'crm.group_manage.write'
export const DESK_UI_SETTINGS_READ = 'crm.ui_settings.read'
export const DESK_UI_SETTINGS_WRITE = 'crm.ui_settings.write'
export const DESK_AUTO_CANNED_READ = 'crm.auto_canned.read'
export const DESK_AUTO_CANNED_WRITE = 'crm.auto_canned.write'
export const DESK_CANNED_RESPONSE_KEYWORD_READ = 'crm.canned_response_keyword.read'
export const DESK_CANNED_RESPONSE_KEYWORD_WRITE = 'crm.canned_response_keyword.write'
export const DESK_CANNED_RESPONSE_ALL_READ = 'crm.canned_response_all.read'
export const DESK_CANNED_RESPONSE_ALL_WRITE = 'crm.canned_response_all.write'
export const DESK_SURVEY_WRITE = 'crm.survey.write'
export const DESK_TICKER_TIMER_WRITE = 'crm.ticket_timer.write'
export const DESK_CUSTOMER_TAG_WRITE = 'crm.customer_tag.write'
export const DESK_WORKING_STATUS_READ = 'crm.working_status.read'
export const DESK_WORKING_STATUS_WRITE = 'crm.working_status.write'
export const DESK_QC_READ = 'crm.qc.read'
export const DESK_QC_WRITE = 'crm.qc.write'
