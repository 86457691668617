import { combineReducers } from 'redux-loop'
import { reducer as toastrReducer } from 'react-redux-toastr'
import cannedResponse from 'modules/cannedResponse/state.js'
import sla from 'modules/sla/state.js'
import user from './user'
import users from './users'
import groups from './groups'
import roles from './roles'
import account from './account'
import payment from './payment'
import ticketTags from './ticketTags'
import templates from './templates'
import userGroupOption from './group-option'
import theme from './custom-theme'
import crm from './crm'
import cms from './cms'

const rootReducer = combineReducers({
  user,
  users,
  roles,
  groups,
  account,
  payment,
  cannedResponse,
  sla,
  ticketTags,
  templates,
  userGroupOption,
  theme,
  crm,
  cms,
  toastr: toastrReducer
})

export default rootReducer
