import React from 'react'
import get from 'lodash/get'
import { Bundle } from 'utils/index'

/**
 * Lazy load for any components for use
 * @param {*} props
 */

export const LoginWithSignup = props => (
  <Bundle load={import('modules/loginWithSignup/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const AccountSetting = props => (
  <Bundle load={import('modules/layouts/AccountSetting')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const Product = props => (
  <Bundle load={import('modules/product/Product')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsSentiment = props => (
  <Bundle load={import('modules/accountSettings/Sentiment/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const PageAccount = props => (
  <Bundle load={import('modules/accountSettings/Account')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const ListeningSocialCustomPage = props => (
  <Bundle load={import('modules/listening/socialCustom/Container')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const PageGroup = props => (
  <Bundle load={import('modules/accountSettings/Group/PageGroups')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const PageBlacklist = props => (
  <Bundle load={import('modules/accountSettings/Blacklist/PageBlacklist')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const PageGroupManage = props => {
  if (get(props, 'match.params.product') === 'crm') {
    return (
      <Bundle load={import('modules/settings/crm/groupManage/main')}>
        {Component => <Component {...props} />}
      </Bundle>
    )
  }
  return (
    <Bundle load={import('modules/groupManage/')}>
      {Component => <Component {...props} />}
    </Bundle>
  )
}

export const PageUser = props => (
  <Bundle load={import('modules/accountSettings/Users/PageUser')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const PagePayment = props => (
  <Bundle load={import('modules/payment/Payment')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const GuestSelectPlans = props => (
  <Bundle load={import('modules/payment/SelectPlan')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const Invoices = props => (
  <Bundle load={import('modules/payment/Invoices')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsSLAChannel = props => (
  <Bundle load={import('modules/sla')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsCannedResponse = props => (
  <Bundle load={import('modules/cannedResponse')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsKeyword = props => (
  <Bundle load={import('modules/keywords/Setting/Container')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const SettingsTemplate = props => (
  <Bundle load={import('modules/templateSettings/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const SettingsTag = props => (
  <Bundle load={import('modules/tags/Setting/Container')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsTicketTags = props => (
  <Bundle load={import('modules/ticketTags/components/Presentation')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const ActivityLog = props => (
  <Bundle load={import('modules/listening/activityLog')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const PunchLog = props => (
  <Bundle load={import('modules/accountSettings/PunchLog/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const HeatMap = props => (
  <Bundle load={import('modules/heatMap')}>
    {Component => <Component {...props} />}
  </Bundle>
)
// export const PageIntegration = props => (
//   <Bundle load={import('modules/accountSettings/Integration')}>
//     {Component => <Component {...props} />}
//   </Bundle>
// )
export const PageIntegration = props => (
  <Bundle load={import('modules/accountSettings/Integration/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const CustomRoles = props => (
  <Bundle load={import('modules/accountSettings/CustomRoles')}>
    {Component => <Component {...props} />}
  </Bundle>
)

// export const SettingCards = props => (
//   <Bundle load={import('modules/payment/SettingCards')}>
//     {Component => <Component {...props} />}
//   </Bundle>
// )

// export const SettingSubscriptions = props => (
//   <Bundle load={import('modules/payment/SettingSubscriptions')}>
//     {Component => <Component {...props} />}
//   </Bundle>
// )

export const SettingSubscriptions = props => (
  <Bundle load={import('modules/accountSettings/Payment/Subscription/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingPaymentList = props => (
  <Bundle load={import('modules/accountSettings/Payment/PaymentList/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const CardSetting = props => (
  <Bundle load={import('modules/accountSettings/Payment/CardSetting/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsCrmAutoAssignment = props => (
  <Bundle load={import('modules/settings/crm/autoAssignment')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsCrmPostBlocker= props => (
  <Bundle load={import('modules/settings/crm/post-blocker/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsCrmQc= props => (
  <Bundle load={import('modules/settings/crm/qc/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsCrmVerification = props => (
  <Bundle load={import('modules/settings/crm/verification/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const SettingsCrmWorkingStatus = props => (
  <Bundle load={import('modules/settings/crm/working-status/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const EmailNotification = props => (
  <Bundle load={import('modules/listening/emailNotification/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const RegisterPage = props => (
  <Bundle load={import('modules/registration/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const resetPasswordPage = props => (
  // <Bundle load={import('modules/resetPassword/')}>
  <Bundle load={import('modules/loginWithSignup/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const forgetPasswordPage = props => (
  // <Bundle load={import('modules/forgetPassword/')}>
  <Bundle load={import('modules/loginWithSignup/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const crmSettingsUI = props => (
  <Bundle load={import('modules/settings/crm/ui/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const engagementSettingPage = props => (
  <Bundle load={import('modules/engagement/')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const cmsSettingInterestTag = props => (
  <Bundle load={import('modules/settings/cms/interest-tag/main')}>
    {Component => <Component {...props} />}
  </Bundle>
)
