import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom"
import { RouteMiddleware } from "utils/index"
import {
  AUTHENTICATED,
  GUEST,
  PAYMENTED,
  // ADDEDCARD,
  // NONPAYMENT,
  ADMIN,
  NOT_OEM,
  PRODUCT_ACTIVATE,
  PRODUCT_SETTING_PAGE,
  PASSWORD_NOT_EXPIRED,
  PERMISSIONS_REQUIRED
} from "constants/Middlewares"
import Logout from "modules/loginWithSignup/Logout"
import queryString from "query-string"
import _ from "lodash"
import * as page from "./pages"
import * as admin from "./adminPages"

import PERMISSIONS_MAP from 'configs/permissionMap'

const AccountSetting = page.AccountSetting
// const Invoices = page.Invoices

let REACT_APP_URL = process.env.REACT_APP_URL
const currentDomain = window.location.origin
if (currentDomain !== process.env.REACT_APP_API_URL) {
  REACT_APP_URL = currentDomain
}

const closeWindowWithAccessToken = props => {
  const queryStringAll = queryString.parse(props.location.search)
  const accessToken = _.get(queryStringAll, "accesstoken", "")
  return window.opener.postMessage(accessToken, REACT_APP_URL), window.close()
}

const RedirectPage = props => {
  const queryStringAll = queryString.parse(props.location.search)
  const from = decodeURI(_.get(queryStringAll, "from", ""))
  const to = _.get(queryStringAll, "to", "")
  const product = _.get(queryStringAll, "p", "")
  window.sessionStorage.setItem("backUrl", from)
  window.sessionStorage.setItem("backProduct", product)
  return <Redirect to={to} />
}

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* Routes for signup & login */}
          <RouteMiddleware
            types={[GUEST]}
            exact
            path="/"
            component={() => <Redirect to={"/login"} />}
            componentFailed={[() => <Redirect to={"/product"} />]}
          />
          <RouteMiddleware
            types={[AUTHENTICATED, ADMIN]}
            exact
            path="/login"
            component={() => <Redirect to={"/admin"} />}
            componentFailed={[
              page.LoginWithSignup,
              () => <Redirect to={"/product"} />
            ]}
          />
          {
            /*
          <RouteMiddleware
            types={[GUEST]}
            exact
            path='/signup'
            component={LoginWithSignup}
            componentFailed={[() => <Redirect to={'/product'} />]}
          /> */
            // USER CAN'T SIGNUP BY THEMSELF FOR NOW
            // BY PASS SIGN UP ONLY !!!
          }

          <Route exact path="/redirect" component={RedirectPage} />

          <Route
            exact
            path="/closeWithToken"
            component={closeWindowWithAccessToken}
          />

          <RouteMiddleware
            types={[AUTHENTICATED]}
            exact
            path="/logout"
            component={Logout}
          />

          {/**
           * NEW PAYMENT PART
           */}
          <RouteMiddleware
            strict
            exact
            types={[GUEST]}
            path="/register_demo_1"
            component={page.RegisterPage}
            componentFailed={[() => <Redirect to={"/product"} />]}
          />

          {/**
           * END NEW PAYMENT
           */}

          <RouteMiddleware
            strict
            exact
            types={[GUEST]}
            path="/reset-password"
            component={page.resetPasswordPage}
            componentFailed={[() => <Redirect to={"/product"} />]}
          />

          <RouteMiddleware
            strict
            exact
            types={[GUEST]}
            path="/forget-password"
            component={page.forgetPasswordPage}
            componentFailed={[() => <Redirect to={"/product"} />]}
          />

          <RouteMiddleware
            strict
            exact
            // types={[AUTHENTICATED, PRODUCT_ACTIVATE, PAYMENTED, NOT_OEM]}
            types={[AUTHENTICATED, PASSWORD_NOT_EXPIRED, NOT_OEM]}
            path="/product"
            component={page.Product}
            componentFailed={[
              () => <Redirect to={"/login"} />,
              () => <Redirect to={"/settings/account/main?page=change-password"} />,
              () => <Redirect to={"/settings/account/main"} />
            ]}
          />

          <RouteMiddleware
            path="/settings/:page/:product"
            types={[AUTHENTICATED, PASSWORD_NOT_EXPIRED]}
            componentFailed={[
              () => <Redirect to={"/login"} />,
              () => <Redirect to={"/settings/account/main?page=change-password"} />
            ]}
            component={props => (
              <AccountSetting {...props}>
                <Switch>
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product"
                    component={page.PageUser}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE]}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/groups"
                    component={page.PageGroup}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.GROUP_SETTING_PAGE}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/system"
                    component={page.PageAccount}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.SYSTEM_SETTING_PAGE}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path='/settings/account/:product/sentiment'
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    component={page.SettingsSentiment}
                    permissionsRequired={PERMISSIONS_MAP.SENTIMENT_SETTING_PAGE}
                    componentFailed={[() => <Redirect to={'/payment'} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/activity"
                    component={page.ActivityLog}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.ACTIVITY_LOG_PAGE}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/punch-log"
                    component={page.PunchLog}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.PUNCH_LOG_PAGE_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/heatmap"
                    component={page.HeatMap}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.HEATMAP_HISTORY}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/integration"
                    component={page.PageIntegration}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.INTEGRATION_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/roles"
                    component={page.CustomRoles}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.CUSTOM_ROLE}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  {/** @desc PAYMENT SETTING */}
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/subscriptions"
                    component={page.SettingSubscriptions}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE]}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/payment-list"
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE]}
                    component={page.SettingPaymentList}
                    componentFailed={[() => <Redirect to={"/payment"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/account/:product/card-setting"
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE]}
                    component={page.CardSetting}
                    componentFailed={[() => <Redirect to={"/payment"} />]}
                  />
                  {/** @desc END PAYMENT SETTING */}

                  <RouteMiddleware
                    exact
                    path="/settings/templates/:product?"
                    component={page.SettingsTemplate}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.TEMPLATE_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/email-notification/:product?"
                    component={page.EmailNotification}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.EMAIL_NOTIFICATION_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/keywords/:product"
                    component={page.SettingsKeyword}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.KEYWORD_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/manage/:product"
                    component={page.PageGroupManage}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.PRODUCT_GROUP_MANAGEMENT}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/sla/:product"
                    component={page.SettingsSLAChannel}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.SLA_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  {/* <RouteMiddleware
                    exact
                    path="/settings/split-tickets/:product"
                    component={page.SplitTicketSetting}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.TICKET_CREATEION_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  /> */}

                  <RouteMiddleware
                    exact
                    path="/settings/canned-response/:product"
                    component={page.SettingsCannedResponse}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.CANNED_RESPONSE_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/social-custom/:product"
                    component={page.ListeningSocialCustomPage}
                    types={[PRODUCT_ACTIVATE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.MONITOR_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/tags/crm"
                    component={page.SettingsTicketTags}
                    types={[PRODUCT_ACTIVATE]}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  // types={[PAYMENTED]}
                  // componentFailed={[() => <Redirect to={'/payment'} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/tags/:product"
                    component={page.SettingsTag}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.TAGS_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/blacklist/:product"
                    component={page.PageBlacklist}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.BLACKLIST_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/auto-assignment/:product"
                    component={page.SettingsCrmAutoAssignment}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.AUTO_ASSIGN_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/post-blocker/:product"
                    component={page.SettingsCrmPostBlocker}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.POST_BLOCKER_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/qc/:product"
                    component={page.SettingsCrmQc}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.QC_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                  <RouteMiddleware
                    exact
                    path="/settings/verification/:product"
                    component={page.SettingsCrmVerification}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.VERIFICATION_LINK_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />               
                  {/* <RouteMiddleware
                    exact
                    path="/settings/auto-sentiment/:product"
                    component={page.SettingsCrmAutoSentiment}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.AUTO_SENTIMENT_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  /> */}
                  <RouteMiddleware
                    exact
                    path="/settings/engagement/:product"
                    component={page.engagementSettingPage}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.ENGAGEMENT_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  {/* }
                  <RouteMiddleware
                    exact
                    path='/settings/account/cards'
                    types={[PAYMENTED]}
                    component={SettingCards}
                    componentFailed={[() => <Redirect to={'/payment'} />]}
                /> */}

                  {/*
                  <RouteMiddleware
                    exact
                    path='/settings/tags/'
                    // path="/settings/tags"
                    component={SettingsTag}
                    path="/settings/ui/:product"
                    component={page.crmSettingsUI}
                    types={[PAYMENTED]}
                    componentFailed={[() => <Redirect to={'/payment'} />]}
                  /> */}

                  <RouteMiddleware
                    exact
                    path="/settings/ui/:product"
                    component={page.crmSettingsUI}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE]}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/interest-tag/:product"
                    component={page.cmsSettingInterestTag}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE]}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />

                  <RouteMiddleware
                    exact
                    path="/settings/working-status/:product"
                    component={page.SettingsCrmWorkingStatus}
                    types={[PRODUCT_ACTIVATE, PRODUCT_SETTING_PAGE, PERMISSIONS_REQUIRED]}
                    permissionsRequired={PERMISSIONS_MAP.WORKING_STATUS_SETTING}
                    componentFailed={[() => <Redirect to={"/product"} />]}
                  />
                </Switch>
              </AccountSetting>
            )}
          />

          <RouteMiddleware
            exact
            path="/payment"
            // types={[AUTHENTICATED, NONPAYMENT]}
            component={page.PagePayment}
            componentFailed={[
              () => <Redirect to={"/login"} />,
              () => <Redirect to={"/product"} />
            ]}
          />

          {/* <RouteMiddleware
            exact
            path='/invoices'
            types={[AUTHENTICATED, PAYMENTED]}
            component={props => (
              <AccountSetting {...props}>
                <Invoices {...props} />
              </AccountSetting>
            )}
            componentFailed={[() => <Redirect to={'/payment'} />]}
          /> */}
          <RouteMiddleware
            exact
            types={[GUEST]}
            path="/plans"
            component={page.GuestSelectPlans}
            componentFailed={[() => <Redirect to={"/product"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin"
            component={admin.PageAdmin}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          {/* <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/account/:account_id"
            component={admin.AdminAccountSettings}
            componentFailed={[() => <Redirect to={"/login"} />]}
          /> */}

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/addaccount"
            component={admin.AdminAccountAdd}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/migrate_keyword"
            component={admin.AdminMigrateKeyword}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/accountrole/:account_id"
            component={admin.AdminRoleSettings}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/permission/:account_id/:role_id"
            component={admin.AdminPermissionSettings}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/remote_user_list/:account_id"
            component={admin.RemoteUsers}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/remote_user_list_new/:account_id"
            component={admin.RemoteUsersNew}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/plan_manage"
            component={admin.AdminPlanManage}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/product_permission"
            component={admin.AdminProductPermissionManage}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/default_role"
            component={admin.AdminDefaultRole}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/plan_manage/add"
            component={admin.AdminPlanSettings}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <RouteMiddleware
            exact
            types={[AUTHENTICATED, ADMIN]}
            path="/admin/plan_manage/:plan_id"
            component={admin.AdminPlanSettings}
            componentFailed={[() => <Redirect to={"/login"} />]}
          />

          <Redirect exact to={"/"} />
        </Switch>
      </Router>
    )
  }
}

export default Routes
